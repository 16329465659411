/* Custom styles for login form */
.con {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: white;
  width: 60%;
  margin: auto;
  
}

.card {
  height: 100%;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(227, 81, 8, 8);
  background-color: #ffffff;
}

.card-body {
  padding: 30px;
}

.logo-image {
  max-width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8%;
  
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

.btn-login {
  background-color: #ff5f15;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  font-family: cursive;
  
}

.btn-login:hover {
  background-color: #cb572d;
}

.select-container {
  position: relative;
}

.select-container select {
  width: 100%;
  padding-right: 30px; 
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; 
}


.form-control:focus {
  border-color: #ee805e !important;
  outline: 0;

}
 

@media screen and (max-width: 374px){
  .con{
    width: 100%;
  }
}




.lg-logo {
    height: 100%;
    max-width: 210px; 
    width: auto; 
  }
  

.sm-logo{
    height: 100%;
    max-width: 50px; /* Limit the maximum width to 50px */
    width: auto; /* Let the width adjust automatically */
}

.sidelogo{
  background-color: #ff5f15;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg:rgb(255, 95, 21) !important;

} 

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(255, 95, 21) !important; 
}

.notification{
  font-size: 25px;
}


/* Basic reset for the footer */
.footer {
  background-color: #ffffff ; 
  color: #0a0b0b;           
  text-align: center;  
  font-weight: bold;     
  padding: 20px;            
  position: fixed;          
  bottom: 0;                
  width: 100%;              
  box-shadow: 0 -2px 10px rgba(234, 231, 231, 0.5);
}

/* Ensures text is not too small on smaller screens */
.footer p {
  margin: 0;
  font-size: 14px;
}

/* Adjustments for different screen sizes */
@media (max-width: 768px) {
  .footer {
    padding: 10px;
  }

  .footer p {
    font-size: 12px;
  }
}




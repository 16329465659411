.form {
  width: 500px; /* Adjust width as needed */
  background-color: white;
  margin-top: 3%;
}

h7{
  color: #0f0e0e;
  font-weight: bold;
  font-size: 16px;
 
}



.form .mb-3 {
  margin-bottom: 15px;
}

.form label {
  font-weight: bold;
  font-size: large;
}

.form-control-sm {
  width: 67%;
  padding: 8px;
  font-size: 16px;
}

.btn1, .btn2 {
  margin-top: 10px;
  padding: 8px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.btn1 {
  background-color: #ff5f15;
  color: #fff;
}

.btn2 {
  background-color: #303030;
  color: #ffffff;
}

button.btn1 {
  width: 88px;
}

.btn1:hover, .btn2:hover {
  opacity: 0.8;
}



/* Category.css */

/* Style the checkbox container */
.checkbox-container {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px; /* Adjust spacing between checkbox and label */
}

/* Style the checkbox input */
.checkbox-input {
  margin-right: 5px; /* Adjust spacing between the checkbox and label */
  margin-top: 13px;
}

/* Style the checkbox label */
.checkbox-label {
  vertical-align: middle;
  margin-top: 17px;
}

/* Add these styles to your Category.css file */

.dropzone {
  position: relative;
}

.dropzone .preview-image {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  margin-right: 10px; /* Optional: Add margin between images */
}

.dropzone-text {
  cursor: pointer;
}

/* .btn3{
  height: 30px;
  width: 100px;
} */

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: rgb(255, 95, 21);
}
div:where(.swal2-icon).swal2-error {
  border-color: red;
}
div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: .3125em;
  border-radius: .125em;
  background-color: red;
}
.form {
    width: 500px; /* Adjust width as needed */
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2); /* Box shadow */
    margin-top: 2%;
  }

  h7{
    color: #0f0e0e;
    font-weight: bold;
  }
  
  .form .mb-3 {
    margin-bottom: 15px;
  }
  
  .form label {
    font-weight: bold;
    font-size: large;
  }
  
  .form-control-sm {
    width: 67%;
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .btn1, .btn2 {
    margin-top: 10px;
    padding: 8px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .btn1 {
    background-color: #ff5f15;
    color: #fff;
  }
  
  .btn2 {
    background-color: #303030;
    color: #fff;
  }
  
  .btn1:hover, .btn2:hover {
    opacity: 0.8;
  }
  
  
  /* Category.css */
  
  /* Style the checkbox container */
  .checkbox-container {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px; /* Adjust spacing between checkbox and label */
  }
  
  /* Style the checkbox input */
  .checkbox-input {
    margin-right: 5px; /* Adjust spacing between the checkbox and label */
    margin-top: 13px;
  }
  
  /* Style the checkbox label */
  .checkbox-label {
    vertical-align: middle;
    margin-top: 17px;
  }
  
  
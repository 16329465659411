/* Coaches.css */

/* Form container */
.Container {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Form title */
h3 {
  margin-bottom: 20px;
}

/* Form labels */
label {
  font-weight: bold;
}

/* Input fields */
.FormControl {
  margin-bottom: 10px;
}

/* Textarea */
.Textarea {
  resize: vertical; /* Allow vertical resizing */
}

/* Submit button */
.SubmitButton {
  background-color: #ff5f15;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 2%;
}

.SubmitButton:hover {
  background-color: #ff5f15
}

.CancelButton {
  background-color: hsl(0, 0%, 5%);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 2%;
  margin-left: 1%;
  
}
.container {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  width: 100%;
  font-weight: 400 !important;
}
.stsearch-box input{
  border: none !important;
}
.stsearch-box input:focus{
  border: none !important;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  border-radius: 5px;
  border-color: #ced4da;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 1.5;
}

.form-control:focus {
  box-shadow: none;
}

.submit-button {
  background-color: rgb(255, 95, 21);
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
  font-weight: bold;
  margin-right: 1%;
}

.cancel{
  background-color: black;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
  font-weight: bold;
  margin-left: 1%;
}


.drag-drop-container {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.uploaded-files-container {
  margin-top: 20px;
}

.uploaded-file {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.uploaded-file span {
  flex: 1;
  padding: 5px;
}

.uploaded-file button {
  background: #ff5f15;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
}

.uploaded-file button:hover {
  background: #e44d12;
}

/* Style the file input button */
input[type="file"] {
  display: none;
}

.drag-drop-container h3 {
  margin: 0;
}

.drag-drop-container .fa-file-upload {
  font-size: 24px;
  margin-right: 10px;
}

/* Style the select from files button */
.select-files-button {
  background: #ff5f15;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.select-files-button:hover {
  background: #e44d12;
}


/* .chip {
  background: #e44d12 !important;
} */